<template>
  <div>
    <div class="wallet-box" :class="!isApp ? 'appTop' : ''">
      <div class="wallet-item" v-for="(item, index) in list" :key="index">
        <div class="item-left">
          <div class="">
            <img src="@/imgs/comm/invit.png" alt="" />
            <p>通联</p>
          </div>
        </div>
        <div class="item-right">
          <div class="">
            <div class="">
              可用余额
              <!-- <span><span class="col"> ¥ </span><span class="col num">49.9</span></span> -->
              <span class="line"> — </span>
            </div>
            <div class="sub">还有200元正在结算中</div>
          </div>
          <!-- <div class="arrow">
          <van-icon name="arrow" />
        </div> -->
          <div class="toauth">去认证</div>
        </div>
      </div>
      <div v-if="!list.length" class="dataNullBox">
        <div>
          <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { nftUtils } from '@/utils';
export default {
  setup() {
    const state = reactive({
      list: [1, 1, 1, 1, 1],
      isApp: nftUtils.isInApp()
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.toauth {
  background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  color: #333;
  border-radius: 5px 5px 5px 5px;
  padding: 3px 8px;
}
.wallet-box {
  padding-top: 30px;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
}
.appTop {
  padding-top: 70px;
}
.arrow {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
}
.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
.line {
}
.wallet-item {
  // width: ;
  background-image: url('../../imgs/comm/walt_bg.png');
  background-size: 100%;
  height: 113px;
  width: 358px;
  margin-left: 8px;
  display: flex;
  .item-left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    width: 30%;
    height: 100%;
    text-align: center;
    img {
      width: 32px;
      height: 32px;
      font-size: 0px;
    }
    p {
      margin: 0px;
    }
  }
  .item-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    width: 62%;

    font-size: 14px;
    .col {
      color: #3cdbe8;
    }

    .num {
      font-weight: 600;
      font-size: 24px;
    }
    .sub {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>
